@import "misc";
@import "colors";

.news-header {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  
    background-color: $secondaryColor;
    color: white;
  
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  
    font-size: 3rem;
    @media (max-width: $mobile) {
        font-size: 24px;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
}